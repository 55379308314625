<template>
    <div class="container">
        <ReportHeader>
            <div class="show_date">
                <ShowYear :year="yearMonth.year" />
                <ShowMonth :months="[yearMonth.month]" style="margin-left: 57px;" />
            </div>
            <div class="small_logo">
                <img src="@/assets/img/customer-week-report/cmj.png" alt="">
            </div>
        </ReportHeader>
        <BgContainer>
            <main>
                <div class="page_title_row">
                    <PageTitle :title="modular.tagName" :isBack="true" />
                    <span class="alias">{{ modular.tagAlias }}</span>
                </div>
                <div class="content_center">
                    <div class="content_box">
                        <div class="tit_row">
                            <h1>{{ article.articleTitle }}</h1>
                            <div class="info">
                                <span class="date">{{ article.showTime }}</span>
                            </div>
                            <div class="user">
                                <div class="header_img">
                                    <img :src="article.releaseAvatar" alt="" v-if="article.releaseAvatar">
                                    <p v-else>{{ article?.releaseName?.slice(0, 1) || '-' }}</p>
                                </div>
                                <p>{{ article.releaseName }}</p>
                            </div>
                        </div>
                        <div class="content" v-html="article.articleContent"></div>
                        <!-- 返回上一页 -->
                        <div class="back_pre_page">
                            <BackPrePage />
                        </div>
                    </div>
                    <!-- 上下一页 -->
                    <div class="page_box"
                        v-if="!keywordId && (prevNext.preData?.articleId || prevNext.nextData?.articleId)">
                        <div class="li" v-if="prevNext.preData?.articleId"
                            @click="onPrevNext(prevNext.preData?.articleId, 'prePage')">
                            <h2>上一篇</h2>
                            <h3>{{ prevNext.preData?.articleTitle }}</h3>
                        </div>
                        <div class="li" v-if="prevNext.nextData?.articleId"
                            @click="onPrevNext(prevNext.nextData?.articleId, 'nextPage')">
                            <h2>下一篇</h2>
                            <h3>{{ prevNext.nextData?.articleTitle }}</h3>
                        </div>
                    </div>
                    <!-- 关联文章 -->
                    <div class="related_box" v-if="keywordId && relatedArticles.length">
                        <h1>相关文章</h1>
                        <ul>
                            <li v-for="item in relatedArticles" :key="item.articleId" @click="onRelated(item)">
                                <p>{{ item.articleTitle }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </main>
        </BgContainer>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import ReportHeader from '@/components/customer-week-report/pc/ReportHeader.vue';
import BgContainer from '@/components/customer-week-report/pc/BgContainer.vue';
import PageTitle from '@/components/customer-week-report/pc/PageTitle.vue';
import { useRoute } from 'vue-router';
import ShowYear from '@/components/customer-week-report/pc/ShowYear.vue';
import ShowMonth from '@/components/customer-week-report/pc/ShowMonth.vue';
import BackPrePage from '@/components/customer-week-report/pc/BackPrePage.vue';

const $route = useRoute();
const props = defineProps({
    article: { type: Object, default: {} },
    prevNext: { type: Object, default: {} },
    modular: { type: Object, default: {} },
    relatedArticles: { type: Array, default: [] },
    yearMonth: { type: Object, default: {} },
})
const emits = defineEmits(['onPrevNext', 'onRelated']);
const keywordId = computed(() => $route.query.keywordId);// 关键词id

function onPrevNext(id, type) {
    emits('onPrevNext', { id, type })
}

function onRelated(e) {
    emits('onRelated', e)
}
</script>

<style lang="scss" scoped>
.container {
    background-color: #E6E6E6;
    min-height: 100vh;

    .show_date {
        border-top: 1px solid var(--week-primary-color);
        position: absolute;
        top: 60px;
        right: 44px;
        padding-top: 30px;
        padding-right: 100px;
        padding-left: 10px;
        display: flex;
        align-items: center;
    }

    .small_logo {
        position: absolute;
        top: 87px;
        right: 52px;
    }

    main {
        width: 100%;
        min-height: calc(100vh - var(--week-pc-header-height));
        background-color: #f5f5f5;
        box-shadow: 0 0 20px #ccc;
        padding: 46px 80px;

        .page_title_row {
            margin-bottom: 29px;

            .alias {
                display: block;
                color: var(--week-primary-color);
                font-size: 14px;
                font-weight: bold;
                margin-left: 25px;
                margin-top: 7px;
                line-height: 1em;
            }
        }

        .content_center {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .content_box {
                flex: 1;
                background-color: #fff;
                box-shadow: 2.7px 7.5px 8.19px 0.81px rgba(0, 0, 0, 0.06);
                position: relative;
                padding-bottom: 50px;

                .tit_row {
                    padding: 50px;
                    padding-bottom: 0;

                    h1 {
                        font-size: 18px;
                        color: #000;
                    }

                    .info {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 25px;

                        .date {
                            color: #999B9F;
                            font-size: 12px;
                        }
                    }

                    .user {
                        display: flex;
                        align-items: center;
                        padding-top: 12px;

                        .header_img {
                            width: 22px;
                            height: 22px;
                            overflow: hidden;
                            border-radius: 50%;
                            margin-right: 8px;
                            text-align: center;
                            background-color: #f2f2f2;

                            img {
                                width: inherit;
                                height: inherit;
                                border-radius: 50%;
                                object-fit: cover;
                            }

                            p {
                                background-color: var(--week-primary-color);
                                line-height: 22px;
                                font-size: 12px;
                                color: #fff;
                            }
                        }


                        p {
                            color: #070A16;
                            font-size: 16px;
                            font-weight: bold;
                            line-height: 22px;
                        }
                    }
                }

                .content {
                    padding: 0 50px;
                    padding-top: 35px;

                    :deep(*) {
                        max-width: 100%;
                        line-height: 2em;
                    }
                }

                .back_pre_page {
                    position: sticky;
                    bottom: 16px;
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .page_box {
                width: 226px;
                margin-left: 28px;

                .li {
                    background-color: #fff;
                    padding: 22px;
                    padding-top: 25px;
                    padding-bottom: 39px;
                    cursor: pointer;
                    box-shadow: 2.7px 7.5px 8.19px 0.81px rgba(0, 0, 0, 0.06);

                    &:first-child {
                        margin-bottom: 18px;
                    }

                    h2 {
                        font-size: 18px;
                        color: #000;
                        padding-bottom: 20px;
                    }

                    h3 {
                        font-size: 14px;
                        color: #000;
                    }
                }
            }

            .related_box {
                width: 226px;
                margin-left: 28px;

                h1 {
                    font-size: 18px;
                    color: #000;
                    padding-bottom: 20px;
                }

                ul {
                    li {
                        background-color: #fff;
                        box-shadow: 2.7px 7.5px 8.19px 0.81px rgba(0, 0, 0, 0.06);
                        width: 100%;
                        cursor: pointer;
                        font-size: 14px;
                        font-weight: bold;
                        padding: 38px 22px;
                        margin-bottom: 20px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

        }
    }
}
</style>