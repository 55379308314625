<template>
    <div class="back_pre_page" @click="onBack">
        <div class="iocn"></div>
        <span>返回上页</span>
    </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';

const $route = useRoute();
const $router = useRouter();

// 返回上一页
function onBack() {
    if (!window.history.state.back) {
        $router.replace({ path: `/week/${$route.params.makerId}` });
    } else {
        $router.go(-1);
    }
}

</script>

<style scoped lang="scss">
.back_pre_page {
    width: 116px;
    height: 32px;
    background-color: #fff;
    box-shadow: 1.36px 3.75px 8.19px 0.81px rgba(0, 0, 0, 0.15);
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center !important;
    cursor: pointer;

    .iocn {
        width: 19px;
        height: 19px;
        background: url(~@/assets/img/customer-week-report/pre_icon.png);
        background-size: cover;
    }

    span {
        padding-left: 10px;
        font-weight: bold;
        font-size: 14px;
    }
}
</style>