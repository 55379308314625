<template>
    <div class="container">
        <ReportHeader>
            <div class="choose_date">
                <ShowYear :year="yearMonth.year" />
            </div>
            <div class="small_logo">
                <img src="@/assets/img/customer-week-report/cmj.png" alt="">
            </div>
            <div class="choose_month">
                <ShowMonth :months="[yearMonth.month]" />
            </div>
            <!-- <HeaderRightLogo /> -->
        </ReportHeader>
        <div class="title_row">
            <PageTitle :title="modular.tagName" :isBack="true" />
            <h1>{{ modular.tagAlias }}</h1>
        </div>
        <main>
            <div class="art_title">{{ article.articleTitle }}</div>
            <div class="date_box">{{ article.showTime }}</div>
            <div class="author">
                <div class="header_img">
                    <img :src="article.releaseAvatar" alt="" v-if="article.releaseAvatar">
                    <p v-else>{{ article?.releaseName?.slice(0, 1) || '-' }}</p>
                </div>
                <p>{{ article.releaseName }}</p>
            </div>
            <div class="content" v-html="article.articleContent" @click="handleArtcleClick"></div>
            <!-- <div class="time_ip">发布于 {{ article.showTime }}</div> -->
        </main>
    </div>
    <!-- 返回上一页 -->
    <BackPrePage />
</template>

<script setup>
import { onMounted, onBeforeUnmount } from 'vue';
import ReportHeader from '@/components/customer-week-report/phone/ReportHeader.vue';
import PageTitle from '@/components/customer-week-report/phone/PageTitle.vue';
import ShowYear from '@/components/customer-week-report/phone/ShowYear.vue';
import ShowMonth from '@/components/customer-week-report/phone/ShowMonth.vue';
import usePreviewImg from './usePreviewImg';
import BackPrePage from '@/components/customer-week-report/phone/BackPrePage.vue';
// import HeaderRightLogo from '@/components/customer-week-report/phone/HeaderRightLogo.vue';

defineProps({
    article: { type: Object, default: {} },
    modular: { type: Object, default: {} },
    yearMonth: { type: Object, default: {} },
})

// 图片预览
const { handleArtcleClick } = usePreviewImg();

onMounted(() => {
    document.body.style.backgroundColor = '#fff';
})

onBeforeUnmount(() => {
    document.body.style.backgroundColor = '#f1f5fa';
})
</script>

<style lang="scss" scoped>
.container {

    .choose_date {
        width: 215px;
        border-top: 1px solid var(--week-primary-color);
        position: absolute;
        top: 60px;
        right: 14px;
        padding-top: 10px;
        padding-left: 80px;
        display: flex;
        align-items: center;
    }

    .small_logo {
        width: 30px;
        position: absolute;
        top: 66px;
        right: 17px;

        img {
            max-width: 100%;
        }
    }

    .choose_month {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 3px;
    }

    .title_row {
        padding: 0 17px;
        padding-top: 27px;
        padding-bottom: 25px;
        background-color: #f5f5f5;

        h1 {
            color: var(--week-primary-color);
            font-size: 16px;
            font-weight: bold;
            margin-top: 8px;
            line-height: 1em;
        }
    }

    main {
        padding: 20px 17px;

        .art_title {
            color: #292929;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
        }

        .date_box {
            margin-top: 4px;
            color: #ACACAC;
            font-size: 14px;
            line-height: 20px;
        }

        .author {
            display: flex;
            align-items: center;
            margin-top: 24px;

            .header_img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 8px;
                background-color: #f2f2f2;
                text-align: center;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                p {
                    background-color: var(--week-primary-color);
                    line-height: 20px;
                    font-size: 12px;
                    color: #fff;
                }
            }

            p {
                color: #292929;
                font-size: 14px;
            }
        }

        .content {
            padding-top: 24px;
            padding-bottom: 20px;
            line-height: 2em;

            :deep(*) {
                max-width: 100%;
                line-height: 2em;
            }
        }

        .time_ip {
            font-size: 12px;
            color: #646464;
            line-height: 20px;
        }
    }
}
</style>