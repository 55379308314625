<template>
    <div class="week_page_title" :class="{ 'cursor_pointer': !disabled }" @click="handleBack">
        <div class="icon_box" v-if="isBack">
            <ArrowLeftBold class="icon" />
        </div>
        <span>{{ title }}</span>
    </div>
</template>

<script setup>
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps({
    title: String,
    isBack: { type: Boolean, default: false },
    to: String,
    disabled: { type: Boolean, default: false },
})
const $route = useRoute();
const $router = useRouter();

// 处理返回事件
function handleBack() {
    if (props.disabled) return;
    if (!props.isBack) return;
    if (props.to) {
        $router.replace(props.to)
    } else if (!window.history.state.back) {
        $router.replace({ path: `/week/${$route.params.makerId}` });
    } else {
        $router.go(-1);
    }
}
</script>

<style lang="scss" scoped>
.week_page_title {
    display: flex;
    align-items: center;

    &.cursor_pointer {
        cursor: pointer;
    }

    .icon_box {
        width: 18px;
        height: 18px;
        background-color: var(--week-primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;

        .icon {
            width: 1em;
            height: 1em;
            color: #fff;
        }
    }

    span {
        color: #2F2E2E;
        font-size: 20px;
        line-height: 1em;
        font-weight: 600;
    }
}
</style>