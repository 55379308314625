<template>
    <PcContainer v-if="device === 'PC'" :article="article" :prevNext="prevNext" :relatedArticles="relatedArticles"
        :modular="modular" :yearMonth="yearMonth" @onPrevNext="onPrevNext" @onRelated="onRelated" />
    <PhoneContainer v-if="device === 'PHONE'" :article="article" :prevNext="prevNext" :relatedArticles="relatedArticles"
        :modular="modular" :yearMonth="yearMonth" />
</template>

<script setup>
import { ref, reactive, watch, computed } from 'vue';
import useIsDevice from '@/utils/customer-week-report/useIsDevice';
import PcContainer from './pc/index.vue';
import PhoneContainer from './phone/index.vue';
import { useRoute, useRouter } from 'vue-router';
import { apiFreeWeeklyArticleId, apiFreeWeeklyArticleNext, apiFreeWeeklyArticleRelated, apiFreeWeeklyModularId } from '@/request/api';
import useWxShare from '@/utils/useWxShare';

// 设备判断 PC | PHONE
const { device, isPHONE } = useIsDevice();
const $route = useRoute();
const $router = useRouter();
const articleId = computed(() => $route.query.articleId);//文章id
const weeklyId = $route.query.weeklyId;// 周报id
const makerId = $route.params.makerId;// 厂商id
const tagId1 = $route.query.tagId1;// 板块id1级
// const tagId2 = $route.query.tagId2;// 板块id2级
// const tagId3 = $route.query.tagId3;// 板块id3级
// const pageNum = computed(() => $route.query.pageNum);
// const pageSize = $route.query.pageSize;
const keywordId = computed(() => $route.query.keywordId);// 关键词id
const modular = ref({});// 版块信息
const relatedArticles = ref([]);// 相关文章
const article = ref({});//文章信息
// 上一条/下一条
const prevNext = reactive({ preData: {}, nextData: {} });
// 返回的pageNum
const pageNums = reactive({
    prePage: null,
    nextPage: null
})
const yearMonth = computed(() => {
    return {
        year: article.value.showTime?.split('-')[0],
        month: article.value.showTime?.split('-')[1],
    }
})

// 获取版块详情
async function setModular() {
    const { data } = await apiFreeWeeklyModularId({ tagId: tagId1 });
    modular.value = data || {};
}

// 获取文章内容
async function setArticle() {
    const { data } = await apiFreeWeeklyArticleId({ articleId: articleId.value, weeklyId, makerId });
    article.value = data || {};
    setShareContent({ title: article.value.articleTitle || '餐饮资讯周报', link: window.location.href, desc: article.value.description });
}

// 获取上下一条
async function setPrevNext() {
    if (isPHONE()) return;
    // const params = { makerId, articleId: articleId.value, pageNum: pageNum.value, pageSize, tagId1, tagId2, tagId3, weeklyId }
    const params = { makerId, ...$route.query };
    const { data } = await apiFreeWeeklyArticleNext(params);
    prevNext.preData = data?.preData || {};
    prevNext.nextData = data?.nextData || {};
    pageNums.prePage = data.prePage;
    pageNums.nextPage = data.nextPage;
}

// 获取相关文章
async function setRelatedArticle() {
    const { data } = await apiFreeWeeklyArticleRelated({ articleId: articleId.value, keywordId: keywordId.value, tagId: tagId1, weeklyId });
    relatedArticles.value = data || [];
}

// 跳转上下
function onPrevNext({ id, type }) {
    $router.replace({ path: `/week/${makerId}/details/`, query: { ...$route.query, articleId: id, pageNum: pageNums[type] } });
}

// 跳转相关文章
function onRelated(e) {
    const routeQuery = { ...$route.query, articleId: e.articleId };
    // delete routeQuery.keywordId;
    $router.replace({ path: `/week/${makerId}/details/`, query: routeQuery });
}

watch(() => articleId.value, (newVal) => {
    if (!newVal) return;
    setArticle();
    if (keywordId.value) {
        setRelatedArticle();
    } else {
        setPrevNext();
    }
})

// 微信分享
const { setShareContent } = useWxShare();

setModular();
setArticle();
if (keywordId.value) {
    setRelatedArticle();
} else {
    setPrevNext();
}
</script>