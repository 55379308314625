import { showImagePreview } from 'vant';

export default () => {
    function handleArtcleClick(e) {
        if (e.target.nodeName !== 'IMG' && e.target.nodeName !== 'img') return;
        showImagePreview({
            images: [e.target.currentSrc],
            showIndex: false,// 页面
            closeable: true,// 关闭按钮
        });
    }

    return { handleArtcleClick }
}